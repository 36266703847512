<template>
  <component :is="'div'">

    <template v-if="groupData">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0">
                Звʼязатись з групою
              </h2>
              <div class="breadcrumb-wrapper">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon
                      icon="HomeIcon"
                      size="16"
                      class="align-text-top"
                    />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    :to="{ name: 'apps-groups-list' }"
                    :key="'Групи водіїв'"
                  >
                    Групи водіїв
                  </b-breadcrumb-item>
                  <b-breadcrumb-item
                    :to="{ name: 'apps-groups-view', params: { id: groupData.id } }"
                    :key="groupData.title"
                  >
                    {{ groupData.title }}
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="groupData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-groups-list'}"
        >
          Group List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-card v-if="groupData">
      
      <b-form
          class="p-2"
          @submit.prevent="sendNotification()"
        >

        <div class="mb-1">
          <h4 class="mb-0">
            Звʼязатись з групою {{ groupData.title }}
          </h4>
        </div>

        <b-form-group
          label="Тема"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="title"
            trim
          />
        </b-form-group>
        
        <b-form-group
          label="Повідомлення"
          label-for="message"
        >
          <b-form-textarea
            id="message"
            v-model="message"
            trim
            style="height: 200px"
          />
        </b-form-group>

        <b-form-checkbox
            v-model="important"
            value="important"
          >
            Важливо
        </b-form-checkbox>

        <div class="d-flex align-items-center mt-2">
          <!-- Form Actions -->
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Надіслати
            </b-button>
          </div>
          <small class="text-success" v-if="sended">Сповіщення надіслано</small>
          <small class="text-danger" v-if="error">Помилка. Сповіщення не надіслано</small>
        </div>

      </b-form> 
    </b-card>     

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BFormCheckbox
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import groupStoreModule from '../groupStoreModule'
import Ripple from 'vue-ripple-directive'
import axiosIns from "@/libs/axios";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,

    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  methods: {
    sendNotification() {

      let formData = new FormData();

        formData.append('import', this.file);
        
        axiosIns.post("/business/notifications/drivers", {
          groupId: this.groupData.id,
          title: this.title,
          message: this.message,
          type: this.important,
        })
        .then(response => {
          this.sended = true
          this.error = false
        })
        .catch(error => {
          this.sended = false
          this.error = true
        });
    }
  },
  setup() {
    const groupData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-group'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-group/fetchGroup', { id: router.currentRoute.params.id })
      .then(response => { 
        
        let group = response.data.items.filter(group => group.id == router.currentRoute.params.id)[0]
        groupData.value = group
      })
      .catch(error => {
        return false
      })

    const message = ''
    const title = ''
    const important = 'normal'
    const sended = false
    const error = false
    
    return {
      groupData,
      message,
      title,
      important,
      sended,
      error,
    }
  },
}
</script>

<style>

</style>
